import {FoodFormState} from "./FoodForm-State";
import {errorsList, errorsPresent, mergeServerValidationErrors} from "../../../../utils/ErrorUtils";
import {clone} from '../../../../utils/DataUtils'

export default function foodFormReducer(state = FoodFormState, action) {
    let {data} = {...state};
    let {validationErrors, branchData, food, food_size_id} = action;
    let size_food = clone(data.food_size);
    // let size_food = {...data.food_size};
    switch (action.type) {
        case 'SET-BRANCH-DATA-FOOD':
            // console.log(` <| branchData |> `,branchData )
            let _new_translation = {};
            branchData && branchData.translation && branchData.translation.map(lang => {
                _new_translation[lang.lang_code] = {
                    name: "",
                    description: ``,
                    ingredients: ``
                }
            });
            data.translation = _new_translation;
            data.parentID = branchData && branchData.parent_id;
            data.restaurantID = branchData && branchData.id;
            data.countryID = branchData && branchData.country_id;
            data.trans = branchData && branchData.translation;
            data.inventory = branchData && branchData.inventory;
            data.breakfast_menu = branchData && branchData.breakfast_menu;
            return {...state, data};

        case 'SET-FOOD':
            let updtranslation = {};
            let adn_cate = [];
            let fdn_cate = [];
            food && food.item_translation.map((item) => {
                updtranslation[item.lang] = {
                    name: item.name,
                    description: item.description,
                    ingredients: item.ingredients
                };
            });
            food && food.item_categories.map(fdn => {
                fdn_cate.push(fdn.id);
            })

            food && food.add_on_categories.map(adn => {
                adn_cate.push(adn.id);
            })

            data.food_size = food.item_sizes;

            data.food_size && data.food_size.length && data.food_size.map((size, i) => {
                let upd_size_translation = size.size_translation;
                let _translation = {};
                upd_size_translation && upd_size_translation.length && upd_size_translation.map((trans) => {
                    _translation[trans.lang] = {
                        title: trans.title
                    }
                });

                delete data.food_size[i].size_translation;
                data.food_size[i].size_translation = _translation;
            })

            // let _gallery_images = [];
            /*food.gallery_images && food.gallery_images.map( g =>{

            } )*/

            data.selected_food_categories = food.food_categories;
            data.food_categories = fdn_cate;
            data.addon_categories = adn_cate;
            data.translation = updtranslation;
            data.status = food.status;
            data.price = food.price;
            data.calorie = food.calorie === null ? 0 : food.calorie;
            data.veg = food.veg;
            data.is_in_house = food.is_in_house;
            data.out_of_stock = food.out_of_stock;
            data.selected_featured_image = food.featured_image;
            data.featured_image = food.featured_image;
            data.gallery = food.gallery_images;
            data.manage_inventory = food.manage_inventory;
            data.qty = food.qty;
            data.discount = food.discount;
            data.food_type = food.food_type;
            data.meta.allergens = food?.meta?.allergens === null ? `` : food?.meta?.allergens;
            data.meta.nutritional_info = food?.meta?.nutritional_info === null ? `` : food?.meta?.nutritional_info;
            return {...state, data};

        case 'SET-DEFAULT-FOOD-FORM':
            data = {
                translation: {},
                status: ``,
                price: ``,
                calorie: ``,
                veg: '',
                is_in_house: '',
                gallery: [],
                out_of_stock: '',
                trans: null,
                discount:'',
                addon_categories: [],
                selected_addon_categories: [],
                food_categories: [],
                selected_food_categories: [],
                restaurantID: ``,
                parentID: ``,
                food_size: [],
                size_index: 0,
                featured_image: '',
                selected_featured_image: ``,
                size_translation: {},
                count_food_size: 0,
                food_size_edit_mode: true,
                food_type: `normal`,
                manage_inventory:``,
                qty:``,
                inventory:``,
                breakfast_menu:``,
                meta:{
                    allergens:``,
                    nutritional_info:``,
                }
            }
            return {...state, data};

        case 'ADD-MORE-FOOD-SIZE':

            // console.log(` <|size_food  |> `, size_food)
            let selectData = data.trans;
            let size_translation = {};
            selectData && selectData.map((lang) => {
                size_translation[lang.lang_code] = {
                    title: ''
                }
            });

            let _food_size = {};

            let is_default = '0';
            if (Object.keys(size_food).length === 0) {
                is_default = '1'
            }

            _food_size = {
                '_id': counterFoodSize() + 1,
                'size': '',
                'is_default': is_default,
                'price': '',
                size_translation,
                calorie: ``
            };
            // console.log(` <| counters  |> `,Object.keys(size_food).length, data.count_food_size )
            size_food.push(_food_size)
            /*if (Object.keys(size_food).length === data.count_food_size) {
              data.size_index = data.count_food_size;
              size_food.push(_food_size)
            }*/
            data.food_size_edit_mode = false;
            data.food_size = size_food;
            return {...state, data};

        case 'REMOVE-FOOD-SIZE':
            data.food_size = data.food_size.filter(item => item._id !== food_size_id)
            return {...state, data}

        default:
            return state;
    }

    function counterFoodSize() {
        let food_size = data.food_size
        if (!food_size.length) {
            return -1
        } else {
            let _id = 0
            food_size && food_size.length && food_size.map((item, i) => {
                if (item._id > _id) {
                    _id = item._id
                }
            })
            return _id
        }
    }
}
