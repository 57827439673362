import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {endOfURL} from "../../../utils/URLUtils";
import useAppState from "../../../hooks/useAppState";
import {useDispatch} from "react-redux";
import getReservationSetting from "../../../ajax/actions/reservations/reservation_setting_get";

const BranchMenu = (props) => {
    // console.log(` <|endOfURL()  |> `,endOfURL() )
    let info_active, food_active, addon_active, addon_category_active, addon_category_sort_active, order_active,
        food_category_active, report_active, reservation_active, reservation_schedule_active,
        reservation_timeslot_active, reservation_internal_active, reservation_setting_active, fault_report_active = ``;
    if (endOfURL() === 'foods') {
        food_active = `active`;
    } else if (endOfURL() === 'addons') {
        addon_active = `active`
    } else if (endOfURL() === 'info') {
        info_active = `active`
    } else if (endOfURL() === 'addons-categories') {
        addon_category_active = `active`
    } else if (endOfURL() === 'sort-addons-category') {
        addon_category_sort_active = `active`
    } else if (endOfURL() === 'food-categories') {
        food_category_active = `active`
    } else if (endOfURL() === 'orders') {
        order_active = `active`
    } else if (endOfURL() === 'report') {
        report_active = `active`
    }else if (endOfURL() === 'fault-report') {
        fault_report_active = `active`
    } else if (endOfURL() === 'setting') {
        reservation_setting_active = `active`
    } else if (endOfURL() === 'reservations') {
        reservation_active = `active`
    } else if (endOfURL() === 'timeslot') {
        reservation_timeslot_active = `active`
    } else if (endOfURL() === 'internal') {
        reservation_internal_active = `active`
    } else if (endOfURL() === 'schedule') {
        reservation_schedule_active = `active`
    }
    const [reservationMode, setReservationMode] = useState(false)
    const [countPendingOrder, setCountPendingOrder] = useState(0)
    const getRestaurantBranch = useAppState(s => s.getRestaurantBranch)
    const getPendingOrdersReducer = useAppState(s => s.getPendingOrdersReducer)
    const reservationSettingGet = useAppState(s => s.reservationSettingGet)
    const dispatch = useDispatch();

    useEffect(() => {
        if (getRestaurantBranch.success) {
            getRestaurantBranch.success && dispatch(getReservationSetting())
        }

    }, [getRestaurantBranch.success])

    useEffect(() => {
        if (getPendingOrdersReducer.success) {
            let _data = getPendingOrdersReducer?.success?.data?.payload;
            setCountPendingOrder(_data.length)
        }

    }, [getPendingOrdersReducer.success])

    useEffect(() => {
        if (reservationSettingGet.success) {
            setReservationMode(true);
        }
    }, [reservationSettingGet.success])
    // console.log(` <| props |> `, props)
    return (
        <div className="col site-dashboard-links">
            <ul>
                <li><Link to={`/branches/${props.id}/info`} className={info_active}><i className="icon-info"/>Dashboard</Link>
                </li>
                <li><Link to={`/branches/${props.id}/orders`} className={order_active}><i className="icon-order"/>Orders {countPendingOrder > 0 && <span className="counter-span">{countPendingOrder}</span>}</Link>
                </li>
                <li><Link to={`/branches/${props.id}/addons-categories`} className={addon_category_active}><i
                    className="icon-addon_cat"/>Addons Category</Link></li>
                <li><Link to={`/branches/${props.id}/sort-addons-category`} className={addon_category_sort_active}><i
                    className="icon-addon_cat"/>Addons Category Sort</Link></li>
                <li><Link to={`/branches/${props.id}/addons`} className={addon_active}><i className="icon-addons"/>Addons</Link>
                </li>
                <li><Link to={`/branches/${props.id}/foods`} className={food_active}><i
                    className="icon-foods"/>Foods</Link></li>
                <li><Link to={`/branches/${props.id}/foods/sort`} className={food_active}><i
                    className="icon-foods"/>Food Sort</Link></li>
                <li><Link to={`/branches/${props.id}/food-categories`} className={food_category_active}><i
                    className="icon-foodcat"/>Foods Category</Link></li>
                <li><Link to={`/branches/${props.id}/reservations`} className={reservation_active}><i
                    className="icon-order"/>Reservations</Link></li>
                {reservationMode ?
                    <>
                        <li><Link to={`/branches/${props.id}/reservations/timeslot`}
                                  className={reservation_timeslot_active}><i className="icon-alarm"/>Reservation
                            TimeSlot</Link></li>
                        <li><Link to={`/branches/${props.id}/reservations/setting`}
                                  className={reservation_setting_active}><i className="icon-order"/>Reservation Setting</Link>
                        </li>
                        <li><Link to={`/branches/${props.id}/reservations/internal`}
                                  className={reservation_internal_active}><i className="icon-order"/>Internal
                            Reservation</Link></li>
                        <li><Link to={`/branches/${props.id}/reservations/schedule`}
                                  className={reservation_schedule_active}><i className="icon-order"/>Reservation
                            Schedule</Link></li>
                    </>
                    : null}

                {props.role === 'branch-admin' && (
                    <>
                        <li>
                            <Link to={`/branches/${props.id}/report`} className={report_active}><i className="icon-report"/>Report</Link>
                        </li>
                        <li>
                            <Link to={`/branches/${props.id}/fault-report`} className={report_active}><i className="icon-report"/>Fault Report</Link>
                        </li>
                    </>
                )}
            </ul>
        </div>

    )
}
export default BranchMenu;