import React, {useEffect, memo, useState} from 'react';
import {useDispatch,} from "react-redux";
import {onSalesReportDateChange, setBranchIDForMonthlyReport,} from "./SalesReport-Action";
import DateField from "../../../common/form/DateField";
import {apiBaseUrl} from "../../../../utils/URLUtils";
import {toast} from "react-toastify";
import {ConfigToastAlert} from "../../../../Config";
import useAppState from "../../../../hooks/useAppState";
import SelectField from "../../../common/form/SelectField";
import {
    fullMonthsWithValues, getStartYearFromNow,
    startYear
} from "../../../../utils/DateTimeUtils";

const MonthlySalesReport = ({props}) => {
    let dispatch = useDispatch();
    const salesReport = useAppState(s => s.salesReport);
    const restaurantBranchList = useAppState(s => s.restaurantBranchList);
    const userDetails = useAppState(s => s.userDetails);
    let {branch_id} = salesReport && salesReport.data;


    function downloadMothlyReport() {
        let message = `Date Field is required`;
        if (!branch_id) {
            message = `Restaurant / Branch ID is required`
        }
        toast.error(message, ConfigToastAlert);
    }

    const _branch_id = userDetails && userDetails.success && userDetails.success.branch_id;
    useEffect( () => {
        _branch_id && dispatch( setBranchIDForMonthlyReport( _branch_id ) )
    }, [ userDetails.success ])
    const branchList = restaurantBranchList && restaurantBranchList.success && restaurantBranchList.success.data.data;
    // let monthly_report = apiBaseUrl(`order/v1/generate/monthly/v2?restaurant_id=${branch_id}&month=${salesReport.data.month}&year=${salesReport.data.year}&access_token=${localStorage.getItem('Bearer')}`);
    let monthly_report = apiBaseUrl(`order/v2/generate/monthly/cli?restaurant_id=${branch_id}&month=${salesReport.data.month}&year=${salesReport.data.year}&access_token=${localStorage.getItem('Bearer')}`);

    let _years = getStartYearFromNow()
    return (
        <>
            <h5>Download Monthly Sales Report</h5>
            <hr/>
            <div className="row">
                <div className="col-md-3">
                    <SelectField name="year"
                                 options={_years}
                                 noLabel
                                 _value="value"
                                 _label="name"
                                 onChange={e => dispatch(onSalesReportDateChange(e))}
                                 value={salesReport.data.year || ''}
                    />
                    {/*<DateField name="monthly_sales_date" noLabel
                               type="month"
                               value={salesReport.data.monthly_sales_date || ''}
                               onChange={(e) => dispatch(onSalesReportDateChange(e))}/>*/}
                    {/*<small>Select date to download monthly report</small>*/}
                    {/*<SelectField name="monthly_sales_date"
                                 options={_newDateValue}
                                 noLabel
                                 onChange={e => dispatch(onSalesReportDateChange(e))}
                                 _value="value"
                                 _label="label"
                                 value={salesReport.data.monthly_sales_date || ''}
                    />*/}
                </div>
                <div className="col-md-3">
                    <SelectField name="month"
                                 options={fullMonthsWithValues}
                                 noLabel
                                 onChange={e => dispatch(onSalesReportDateChange(e))}
                                 _value="value"
                                 _label="name"
                                 value={salesReport.data.month || ''}
                    />
                </div>
                { !_branch_id && (
                    <div className="col-md-3">
                        <SelectField name="branch_id"
                                     options={branchList}
                                     noLabel
                                     onChange={e => dispatch(setBranchIDForMonthlyReport(e.target.value))}
                                     _value="id"
                                     _label="branch_name"
                                     value={branch_id}
                        />
                    </div>
                ) }
                <div className="col-md-3 text-right">
                    {(branch_id && salesReport.data.month && salesReport.data.year) ?
                        <a href={monthly_report} target="_blank" className={`btn-theme`} id="btn-filter">Download
                            Monthly
                            Report</a>
                        :
                        <button className={`btn-theme`} id="btn-filter" onClick={() => downloadMothlyReport()}>
                            Download Monthly Report
                        </button>
                    }

                </div>
            </div>
        </>
    )
}

export default memo(MonthlySalesReport);