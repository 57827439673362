import {urlParamValue} from "../../../../utils/URLUtils";

const DefaultFoodsListState = {
    params: {
        limit: urlParamValue(`limit`) || 10,
        page: urlParamValue(`page`) || 1,
        sort_by: urlParamValue(`sort_by`) || 'desc',
        sort_column: urlParamValue(`sort_column`) || `id`,
        q: urlParamValue(`q`) || ``,
        food_type:urlParamValue(`food_type`) || ``,//general
        category_id:urlParamValue(`category_id`) || ``

    },
    fields: [
        {field: `id`, label: `ID`, sort_icon:true, sort: true, sort_type:'desc'},
        {field: `image`, label: `Image`, sort_icon:false, sort: false},
        {field: `name`, label: `Name`, sort_icon:false, sort: false},
        {field: `price`, label: `Price`,sort_icon:true, sort: true, sort_type:'desc'},
        {field: `calorie`, label: `Calorie (Kcal)`,sort_icon:true, sort: true, sort_type:'desc'},
        {field: `qty`, label: `Quantity`,sort_icon:false, sort: false, sort_type:'desc'},
        {field: `discount_percentage`, label: `Discount`,sort_icon:false, sort: false, sort_type:'desc'},
        // {field: `food_type`, label: `Food Type`,sort_icon:false, sort: false},
        {field: `out_of_stock`, label: `Out Of Stock`,sort_icon:false, sort: false},
        {field: `created_at`, label: `Created`,sort_icon:true, sort: true, sort_type:'desc'},
        {field: `status`, label: `Status`,sort_icon:false, sort: false},
        // {field: `action`, label: `Action`, sort: true},
        // {field: ``, label: ``, sort_icon:false,sort: true},
        // {field: ``, label: ``,sort_icon:false, sort: true},
    ],
    food_filter_open:false,
    food_categories_list:[],
}

export const FoodsListState = {data: DefaultFoodsListState}