import React, { memo } from "react";
import { names, ucFirst, unslufigy } from "../../../utils/StringUtils";
import Div from "../Div/Div";

const NumberField = ( props ) =>
{
  const { name, size, refer, value, icon, onChange, id, noLabel, labelIcon, label, touched,
    addonAfter, disabled, className, error, required, placeholder, onKeyUp, autoFocus } = props;

  const hasError = error && touched;

  const setPlaceholder = () =>
  {
    if ( placeholder ) return placeholder
    if ( label ) return label
    else {
      if ( name ) {
        let _label = unslufigy( name );
        if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
        return _label;
      }
    }
  }

  const Label = () =>
  {
    if ( label ) return (
        <Div className={ names(`label-text`) }>
          { label } { hasError ? error + '.' : null }
        </Div>
    );
    else {
      if ( name ) {
        let _label = unslufigy( name );
        if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
        return (
            <Div className={ names(`label-text`) }>
              { _label } { hasError ? error + '.' : null }
            </Div>
        )
      }
    }
    return null
  }
  return (

      <Div className={ names(`form-group`, hasError && `has-error`) }>

        <label><Label /></label>

        { icon }

        <input type="number"
               ref={ refer }
               name={ name }
               value={ value || "" }
               id={ id || name }
               onKeyUp={ onKeyUp }
               onChange={ onChange }
               autoFocus={ autoFocus }
               disabled={ !!disabled }
               placeholder={ setPlaceholder() }
               className={ names(`form-control`, className ) } />

      </Div>
  )

}
export default memo( NumberField );