import React,{ useState, useReducer } from 'react';
import {useDispatch, useSelector} from "react-redux";
import TextField from "../../../common/form/TextField";
import {useParams,} from "react-router";
import {useFormik,} from "formik";
import * as Yup from "yup";
import SelectField from "../../../common/form/SelectField";
import {activeStatus, foodTypeOptions, validImagType, yesOrNoOptions} from "../../../../utils/StaticDataUtils";
import {names, slugify, ucFirst} from "../../../../utils/StringUtils";
import createFood from "../../../../ajax/actions/foods/food_post";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import updateFood from "../../../../ajax/actions/foods/food_put";
import FoodWorker from "./FoodWorker";
import NumberField from "../../../common/form/NumberField";
import TextAreaField from "../../../common/form/TextAreaField";
import CheckboxField from "../../../common/form/CheckboxField";
import UploadImage from "../../../common/form/UploadImage";
import FoodSizeForm from "./FoodSizeForm";
import {addMoreFoodSize, counterFoodSize} from "./FoodForm-Actions";
import {useTitle} from "react-use";
import {ConfigToastAlert, Title} from "../../../../Config";
import { Scrollbars } from 'react-custom-scrollbars';
import "./_food.scss"
import Div from "../../../common/Div/Div";
import { ArabicTranslate, EnglishTranslate } from '../../../../utils/GeneralUtils';
import { toast } from 'react-toastify';
const FoodForm = () => {
    const[ tempGallery, setTempGallery ] = useState([])

    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {foodCreate, foodForm, foodUpdate, getAddonsCategoriesReducer, foodCategoriesSortGet, getRestaurantBranch} = reduxState;
    let {id} = useParams();

    useTitle(Title.name + ` Food Form`);


    let {trans, inventory, breakfast_menu } = foodForm.data;
    let fields = {...foodForm.data}

    const {handleChange, handleSubmit, errors, touched, values, setFieldValue,} = useFormik(
        {
            initialValues: fields,
            enableReinitialize: true,

            validationSchema: Yup.object().shape({
                price: Yup.number().typeError(' must be a number').required(' is required'),
                calorie: Yup.number().typeError(' must be a number'),
                discount: Yup.number().typeError(' must be a number').required(' is required'),
                status: Yup.bool().required('is required'),
                food_categories: Yup.array().required(` is required`),
                // addon_categories: Yup.array().required(` is required`),
                qty:Yup.number().typeError(' must be a number').when("manage_inventory", {
                    is: 1,
                    then: Yup.number().required(" is required")
                }),
                translation: Yup.object().shape({
                    en: Yup.object().shape({
                        name: Yup.string().required(` is required`),
                    }),
                    ar: Yup.object().shape({
                        name: Yup.string().required(` is required`),
                    })
                })
            }),

            onSubmit: (values) => {
                if (id) {
                    dispatch(updateFood(values, id))
                } else {
                    dispatch(createFood(values))
                }
            }
        })

    const handleChangeCheckBox = (e) => {
        let {value, name} = e.target;
        let _values = {...values};
        // console.log(` <| value for target |> `, value)
        if (_values[name].includes(+value)) {
            _values[name] = _values[name].filter(item => item !== +value);
        } else {
            _values[name].push(+value);
        }

        setFieldValue(name, _values[name])
    }

    const onFoodTranslationFormChange = e => {
        let { value, name } = e.target;
        let text = '';
        if (e.target.id === 'ar') {
            if (ArabicTranslate(e.target.value) === undefined) {
                return
            } else {
                text = ArabicTranslate(e.target.value)
            }
        } else {
            if (EnglishTranslate(e.target.value) === undefined) {
                return
            } else {
                
                text = EnglishTranslate(e.target.value);
            }
        }
        // setFieldValue(name, value)
        setFieldValue(name, text)
    }

    const handleMetaChange = e => {
        let {value, name} = e.target;
        setFieldValue(`meta.${[name]}`, value)
    }

    const handleSizeChange = e => {
        let { value, name, } = e.target;
        let text = '';
        
        const _id = e.target.id.split('-')[1]
        if (_id === 'ar') {
            if (ArabicTranslate(e.target.value) === undefined) {
                return
            } else {
                text = ArabicTranslate(e.target.value)
            }
        } else {
            if (EnglishTranslate(e.target.value) === undefined) {
                return
            } else {
                
                text = EnglishTranslate(e.target.value);
            }
        }
        setFieldValue(name, text)
    }

    const onHandleSizeDefaultChange = (e, ids, key ) => {
        // console.log(` <| ids |> `,ids, key )
        const { food_size } = values;
        food_size && food_size.length && food_size.map((fs, i) => {
            if( id ) {
                if( fs.id === ids ) {
                    setFieldValue(`food_size.${key}.is_default`, '1')
                } else {
                    setFieldValue(`food_size.${i}.is_default`, '0')
                }
            } else {
                if( fs._id === ids ) {
                    setFieldValue(`food_size.${key}.is_default`, '1')
                } else {
                    setFieldValue(`food_size.${i}.is_default`, '0')
                }
            }
        })
    }

    const handleSizeSlugChange = e => {
        let {value, name, id} = e.target;
        let ids = id.split('-');
        setFieldValue(`food_size.${ids[0]}.size`, slugify(value))
        setFieldValue(name, value)
    }

    const handleImage = e => {
        let {value, name} = e.target;
        let reader = new FileReader();
        let file = e.target.files[0];
        
        if (validImagType.includes(file.type)) {
            if (file.size > 400000) {
             toast.error(` Image Size too Large`, ConfigToastAlert );
            // this.setState({ sizeErrorFeatured: true })
            } else {
                reader.onloadend = () => {
                    // let state = this.state;
                    // state['featured_image'] = reader.result;
                    // this.setState({ ...state });
                    setFieldValue(`featured_image`, reader.result)
                };
                reader.readAsDataURL(file);
            }
        } else {
            toast.error(` Not Valid file type`, ConfigToastAlert );
        }
        
        // console.log(` <| value, name |> `, value, name)
    }

   function _handleUploadedGalleryFiles(file)
    {
        let reader = new FileReader();
        let _values = {...values};
        // let that = this;
        reader.onloadend = function(e){
            if (e.target.readyState === FileReader.DONE) {
                _values['gallery'].push(e.target.result);
                // tempGallery.push(e.target.result);
                setFieldValue(`gallery`, _values['gallery']);
                // that.setState({ gallery: that.tempGallery });
            }
        };
        reader.readAsDataURL(file);
    }

    const _handleGalleryChange = (e) =>
    {
        // e.preventDefault();
        let files = Array.from(e.target.files);
        let sizeErrorGallery = false;
        files.map(file => {
            // file.size > Config.ImageUploadSizeLimit
            if (validImagType.includes(file.type)) {

                if (file.size > 400000) {
                    sizeErrorGallery = true;
                    toast.error(` Image Size too Large`, ConfigToastAlert );
    
                }
            } else {
                sizeErrorGallery = true;
                 toast.error(` Not valid file type`, ConfigToastAlert );
            }
        });

        if (sizeErrorGallery) {
            // this.setState({ sizeErrorGallery: true })

        } else {
            for(let i=0; i<files.length; i++){
                _handleUploadedGalleryFiles(files[i]);
            }
        }
    }

    const removeImage = (e)  => {
        // console.log(` <| e.target.id |> `, e.target.id )
        let _values = {...values};
        for(let i=0; i<_values.gallery.length; i++){
            if(+e.target.id === i){
                let index = _values.gallery.indexOf(_values.gallery[i]);
                _values.gallery.splice(index, 1);
                // this.setState({ gallery });

            }
        }
        setFieldValue(`gallery`, _values.gallery);
    }

    const removeFeatureImage = (e)  => {
        setFieldValue(`featured_image`, ``);
        setFieldValue(`selected_featured_image`, ``);
    }

    const addMoreFoodSize = () => {
        let _values = {...values};
        let size_food = _values.food_size;
        let selectData = _values.trans;
        let size_translation = {};
        selectData && selectData.map((lang) => {
            size_translation[lang.lang_code] = {
                title: ''
            }
        });

        let _food_size = {};

        let is_default = '0';
        if (Object.keys(size_food).length === 0) {
            is_default = '1'
        }

        _food_size = {
            '_id': counterFoodSize(_values) + 1,
            'size': '',
            'is_default': is_default,
            'price': '',
            size_translation,
            calorie: ``
        };
        size_food.push(_food_size)

        _values.food_size_edit_mode = false;
        setFieldValue('food_size', size_food)
    }

    const removeFoodSize = ( food_size_id ) => {
        // console.log(` <| food_size_id |> `,food_size_id )
        let _values = {...values};
        _values.food_size = _values.food_size.filter(item => item._id !== food_size_id );
        setFieldValue("food_size", _values.food_size);
    }

    // console.log(` <| foodForm values |> `, values)
    let processing = foodCreate.processing || foodUpdate.processing;

    let addon_categories_list = getAddonsCategoriesReducer?.success?.data?.data;

    let addon_category_option = [];
    addon_categories_list && addon_categories_list.map(list => {
        addon_category_option.push({value: list.id, label: list.name})
    })

    let selected_addoncate_list = [];
    values.selected_addon_categories && values.selected_addon_categories.map(c => {
        selected_addoncate_list.push({value: c.id, label: c.name})
    });

    let food_categories_list = foodCategoriesSortGet?.success?.data?.payload;
    let food_categories_option = [];
    food_categories_list && food_categories_list.map(list => {
        food_categories_option.push({value: list.id, label: list.name})
    })

    // console.log(` <| errors |> `, errors)
    // console.log(` <| touched |> `, touched)
// console.log(` <| trans |> `, trans)
    let formProcessing = getAddonsCategoriesReducer.processing || foodCategoriesSortGet.processing || getRestaurantBranch.processing;

    return (
        <div className="plr-35 w-1000">
            <FoodWorker/>
            <>
                {formProcessing ? <GrowingLoader/> :
                    <form action="" onSubmit={handleSubmit}>
                        <div className="row">
                            {trans && trans.map((t) => {
                                let lang = t.lang_code === 'ar' ? `Arabic` : `English`;
                                return (<>
                                    <div className="col-sm-6" key={`${t.lang_code}`}>
                                        <TextField
                                            name={`translation.${t.lang_code}.name`}
                                            id={t.lang_code}
                                            label={`Food Name (${t.lang_code === 'ar' ? 'Arabic' : 'English'})`}
                                            placeholder={`Enter Food Name in ${lang}`}
                                            onChange={onFoodTranslationFormChange}
                                            className={ t.lang_code === 'ar' ? 'text-translation' : ''}
                                            value={values?.translation?.[t.lang_code]?.name}
                                            error={errors?.translation?.[t.lang_code]?.name}
                                            touched={touched?.translation?.[t.lang_code]?.name}
                                        />
                                        <TextAreaField name={`translation.${t.lang_code}.description`}
                                                       placeholder={`Enter Description in ${lang}`}
                                            noLabel
                                            id={t.lang_code}
                                            onChange={onFoodTranslationFormChange}
                                            className={ t.lang_code === 'ar' ? 'text-translation' : ''}
                                                       value={values?.translation?.[t.lang_code]?.description}
                                        />
                                        {/*<TextField
                                        name={`translation.${t.lang_code}.ingredients`} noLabel
                                        placeholder={`Ingredients separated by comma (eg. potato, onion) ${lang}`}
                                        onChange={onFoodTranslationFormChange}
                                        value={values.translation && values.translation[t.lang_code] && values.translation[t.lang_code].ingredients}
                                    />*/}
                                    </div>
                                </>)
                            })}

                            <div className="col-sm-6">
                                <TextField
                                    name="price"
                                    placeholder="Price"
                                    onChange={handleChange}
                                    value={values.price}
                                    error={errors && errors.price}
                                    touched={touched.price}
                                />

                            </div>
                            <div className="col-sm-6">
                                <TextField
                                    name="calorie"
                                    placeholder="Calorie"
                                    onChange={handleChange}
                                    value={values.calorie}
                                    error={errors && errors.calorie}
                                    touched={touched.calorie}
                                />
                            </div>

                            {breakfast_menu === 'enabled' ?
                                <div className="col-md-6">
                                    <SelectField
                                        name="food_type"
                                        options={foodTypeOptions}
                                        onChange={handleChange}
                                        label="Item Type"
                                        value={values.food_type}
                                        _label="name"
                                        _value="value"

                                        error={errors && errors.food_type}
                                        touched={touched.food_type}
                                    />
                                </div> : null
                            }

                            <div className="col-md-6">
                                <TextField
                                    name="discount"
                                    placeholder="Discount"
                                    onChange={handleChange}
                                    value={values.discount}
                                    error={errors && errors.discount}
                                    touched={touched.discount}
                                />
                            </div>

                            <div className="col-sm-12">
                                {food_categories_option.length > 20 ?
                                    <>
                                        <div className={`form-group ${ ( errors && errors.food_categories ) ? 'has-error' : ''}`}>
                                            <label>

                                                <div className={names(`label-text `)}>
                                                    Food Category { errors && errors.food_categories}
                                                </div>
                                            </label>
                                        </div>
                                        <Scrollbars style={{height: 300}}
                                                    renderTrackHorizontal={props => <div {...props}
                                                                                         className="track-horizontal"
                                                                                         style={{display: "none"}}/>}
                                                    renderThumbHorizontal={props => <div {...props}
                                                                                         className="thumb-horizontal"
                                                                                         style={{display: "none"}}/>}>
                                            <div className="addon-class">
                                                <CheckboxField options={food_categories_list}
                                                               processing={foodCategoriesSortGet.processing}
                                                               label="Food Category"
                                                               noLabel
                                                               name="food_categories"
                                                               value={values.food_categories}
                                                               error={errors && errors.food_categories}
                                                               touched={touched.food_categories}
                                                               onChange={handleChangeCheckBox}/>
                                            </div>
                                        </Scrollbars>
                                    </>
                                    : <CheckboxField options={food_categories_list}
                                                     processing={foodCategoriesSortGet.processing}
                                                     label="Food Category"
                                                     name="food_categories"
                                                     value={values.food_categories}
                                                     error={errors && errors.food_categories}
                                                     touched={touched.food_categories}
                                                     onChange={handleChangeCheckBox}/>
                                }
                            </div>

                            {inventory ?
                                <>
                                    <div className="col-md-6">
                                        <SelectField
                                            name="manage_inventory"
                                            options={yesOrNoOptions}
                                            onChange={handleChange}
                                            label="Manage Inventory"
                                            value={values.manage_inventory}
                                            _label="name"
                                            _value="id"

                                            error={errors && errors.manage_inventory}
                                            touched={touched.manage_inventory}
                                        />
                                    </div>
                                    {+values.manage_inventory === 1 ?
                                        <div className="col-md-6">
                                            <TextField
                                                name="qty"
                                                placeholder="Quantity"
                                                onChange={handleChange}
                                                value={values.qty}
                                                error={errors && errors.qty}
                                                touched={touched.qty}
                                            />
                                        </div> : null}
                                </> : null}

                            <div className="col-sm-6">
                                <SelectField
                                    name="veg"
                                    options={yesOrNoOptions}
                                    onChange={handleChange}
                                    label="Vegetarian"
                                    value={values.veg}
                                    _label="name"
                                    _value="id"

                                    error={errors && errors.veg}
                                    touched={touched.veg}
                                />
                            </div>

                            <div className="col-sm-6">
                                <SelectField
                                    name="is_in_house"
                                    options={yesOrNoOptions}
                                    onChange={handleChange}
                                    label="In House"
                                    value={values.is_in_house}
                                    _label="name"
                                    _value="id"

                                    error={errors && errors.is_in_house}
                                    touched={touched.is_in_house}
                                />
                            </div>

                            <div className="col-sm-6">
                                <SelectField
                                    name="out_of_stock"
                                    options={yesOrNoOptions}
                                    onChange={handleChange}
                                    label="Out Of Stock"
                                    value={values.out_of_stock}
                                    _label="name"
                                    _value="id"

                                    error={errors && errors.out_of_stock}
                                    touched={touched.out_of_stock}
                                />
                            </div>
                            <div className="col-sm-6">
                                <TextField
                                    name="allergens"
                                    placeholder="Allergens"
                                    onChange={handleMetaChange}
                                    value={values?.meta?.allergens}
                                    error={errors && errors.allergens}
                                    touched={touched.allergens}
                                />
                            </div>
                            <div className="col-sm-6">
                                <TextAreaField name={`nutritional_info`}
                                               placeholder={`Enter nutritional info`}
                                               noLabel
                                               onChange={handleMetaChange}
                                               value={values?.meta?.nutritional_info}
                                />
                            </div>
                            <div className="col-sm-6">
                                <SelectField
                                    name="status"
                                    options={activeStatus}
                                    onChange={handleChange}
                                    label="Status"
                                    value={values.status}
                                    _label="name"
                                    _value="value"

                                    error={errors && errors.status}
                                    touched={touched.status}
                                />
                            </div>

                            <div className="col-sm-12">
                                {addon_category_option.length > 20 ?
                                    <>
                                        <div className={names(`label-text`)}>
                                            Addon Category
                                        </div>
                                        <Scrollbars style={{ height: 300 }}
                                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}>
                                            <div className="addon-class">
                                                <CheckboxField options={addon_categories_list}
                                                               label="Addon Category"
                                                               noLabel
                                                               name="addon_categories"
                                                               processing={getAddonsCategoriesReducer.processing}
                                                               value={values.addon_categories}
                                                               error={errors && errors.addon_categories}
                                                               touched={touched.addon_categories}
                                                               onChange={handleChangeCheckBox}/>
                                            </div>
                                        </Scrollbars>
                                    </>:

                                    <CheckboxField options={addon_categories_list}
                                            label="Addon Category"
                                            name="addon_categories"
                                            processing={getAddonsCategoriesReducer.processing}
                                            value={values.addon_categories}
                                            error={errors && errors.addon_categories}
                                            touched={touched.addon_categories}
                                            onChange={handleChangeCheckBox}/>
                                }
                            </div>

                            <div className="col-sm-6 mt-15">
                                <UploadImage name="featured_image"
                                             onChange={handleImage}
                                             uploadType="featured"
                                             onRemoveImage={removeFeatureImage}
                                             value={values.featured_image ? values.featured_image : values.selected_featured_image}/>
                            </div>
                            <div className="col-sm-6 mt-15">
                                <UploadImage name="gallery_image"
                                             onChange={_handleGalleryChange}
                                             multiple
                                             uploadType="multiple"
                                             onRemoveImage={removeImage}
                                             value={values.gallery}/>
                            </div>

                            <div className="col-md-12">
                                <div className="add-food-block">
                                    <div className="add-food-heading">
                                        <p>Add Food Size</p>
                                        <span>
                                        {/*<a href="javascript:void(0)" className="text-theme"
                                             onClick={() => dispatch(addMoreFoodSize())}>+Add New Food Size</a>*/}
                                            <a href="javascript:void(0)" className="text-theme"
                                               onClick={() => addMoreFoodSize()}>+Add New Food Size</a>
                                    </span>
                                    </div>
                                    {values.food_size && values.food_size.length ?
                                        <FoodSizeForm onChange={handleSizeChange}
                                                      values={values}
                                                      removeFoodSize={removeFoodSize}
                                                      updateMode = {id ? 'update' : 'create'}
                                                      onHandleSizeDefault={onHandleSizeDefaultChange}
                                                      slugChange={handleSizeSlugChange}/> : null}
                                </div>
                            </div>
                        </div>
                        <button type="submit"
                                className="btn-theme mt-10">{processing ?
                            <GrowingLoader/> : `${id ? "Update" : "Create"}`}
                        </button>
                    </form>
                }
            </>
        </div>
    )
}
export default FoodForm;