import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import { handleError, handleSuccess } from '../../../utils/ErrorUtils'
import { AppSource } from '../../../Config';

function _success(success)
{
    return { type: 'SORT_FOOD_CATEGORIES_SUCCESS', success };
}
function _error(error)
{
    return { type: 'SORT_FOOD_CATEGORIES_ERROR', error };
}
function _processing(processing)
{
    return { type: 'SORT_FOOD_CATEGORIES_PROCESSING', processing };
}

function sortFoodCategories( items, branch_id )
{
    return ( dispatch, getState ) =>
    {
        let {main} = getState();
        let {user_role} = main.data;
        dispatch( _success( false ) );
        dispatch( _error( false ) );
        dispatch(_processing(true));

        let finalData ={
            branch_id, category_info:items
        }

        let url = ``;
        if (user_role === 'restaurant-admin') {
            url = `vendor/v1/vendor/${branch_id}/item/category/sort`
        } else if (user_role === `branch-admin`) {
            url = `vendor/v1/vendor/item/category/sort`
        }

        axios({
            // url: apiBaseUrl(`restaurant/v1/category/sort`),
            url: apiBaseUrl(url),
            method: "post",
            dataType: 'json',
            data: JSON.parse(JSON.stringify(finalData)),
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'x-app-source': AppSource.appSource
            }
        })
            .then(function(res){
                dispatch(_success(res));
                handleSuccess(res);

            }).catch(function(error){
                handleError( error )
                dispatch(_error(error));
            });
    }
}

export default sortFoodCategories;