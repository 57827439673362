import React, {useEffect, useState, memo} from 'react';
// import { apiBaseUrl, urlParamValue } from '../../../utils/URLUtils'
// import { useParams } from 'react-router-dom';
// import axios from "axios";
import VerifyCode from "../../../ajax/actions/integration/integration_post";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router";

const FoodicsCallback = () => {
    let dispatch = useDispatch();
    // const [loading, setLoading] = useState(true);
    const location = useLocation();


    useEffect(async () => {
        const fetchData = async () => {
            try {
                const searchParams = new URLSearchParams(location.search);
                const code = searchParams.get('code');
                const result = await dispatch(VerifyCode({ code, source: 'external', type: 'foodics' }));

                // Check if window.opener is defined before using it
                if (window.opener) {
                    const eventData = { type: 'oAuthSuccess', data: result.data };
                    window.opener.postMessage(eventData, window.location.origin);
                }
                window.close();
            } catch (error) {
                // Check if window.opener is defined before using it
                if (window.opener) {
                    const eventData = { type: 'oAuthError', data: error };
                    window.opener.postMessage(eventData, window.location.origin);
                }
                window.close();
            }
        };

        await fetchData();
    }, [dispatch, location.search]);

    return (
        <>
            <div className="overlay-container">
                <div className="overlay">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(FoodicsCallback);