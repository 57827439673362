import React, {useState,useEffect}  from "react";
import {useDispatch, useSelector} from 'react-redux'
import useAppState from "../../../../hooks/useAppState";
import SelectField from "../../../common/form/SelectField";
import updateOrder from "../../../../ajax/actions/Orders/order_put";
import {useFormik} from "formik";
import {fiveMinuteTimeFrame, orderStatusOptions} from "../../../../utils/StaticDataUtils";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import {
    getCurrentDate,
    getTodayDateAndTime,
    getTodayDateAndTimeWithAdded,
    getTodaysDay,
    today
} from "../../../../utils/DateTimeUtils";
import TextField from "../../../common/form/TextField";
import getOrderRejectStatusReducer from "../../../../ajax/reducers/Orders/order-reject-status-get";
import getOrderRejectStatus from "../../../../ajax/actions/Orders/order_reject_status_get";
import getOrder from "../../../../ajax/actions/Orders/order_get";

const OrderStatusUpdateForm = ({orders, id }) =>
{

    const dispatch = useDispatch();
    const [ submitted, setSubmitted ] = useState( false )
    const [ time, setTime ] = useState( 15 )

    const updateOrderReducer = useAppState( s => s.updateOrderReducer );
    const getOrderRejectStatusReducer = useAppState( s => s.getOrderRejectStatusReducer );

    useEffect( () => {
        if( updateOrderReducer.success ) {
            dispatch( getOrder( id ) );
        }
    },[updateOrderReducer.success])

    const { handleChange, handleSubmit, errors, touched, values, handleBlur, setFieldValue } = useFormik(
        {
            initialValues: {
                order_status: orders && orders.order_status,
                delivery_status: orders && orders.delivery_status,
                payment_status: orders && orders.payment_status,
                api_delivery_time: 15//getTodayDateAndTimeWithAdded( 15 )//getTodayDateAndTime(),
            },
            enableReinitialize: true,

            onSubmit: values => {
                if( values.order_status === 'accepted' ) {
                    delete values.rejection_reason_id

                } else if( values.order_status === 'rejected' ) {
                    delete values.api_delivery_time;
                    delete values.handled_by
                }
                 dispatch(updateOrder(values, id));
            }
        })

    const onSubmit = e =>
    {
        setSubmitted( true )
        handleSubmit( e )
    }

    const onHandleOrderStatusChange = (value) => {
        setFieldValue( `order_status`, value )
        if( value === 'rejected' ) {
            !getOrderRejectStatusReducer.success && dispatch( getOrderRejectStatus() );
        }
        else if( value === `accepted` ) {
            // setFieldValue( `api_delivery_time`, getTodayDateAndTime() )
            setFieldValue( `api_delivery_time`, 15 )
        }
    }

    const handleMinuteChange = (value) => {
        setTime( value );
        // setFieldValue(`api_delivery_time`, getTodayDateAndTimeWithAdded( value ))
        setFieldValue(`api_delivery_time`,  value )
        /*let dt = new Date()
        console.log(` <| new minutes |> `, new Date(dt.getTime() + value*60000) )*/
    }

    const orderRejectStatus = getOrderRejectStatusReducer && getOrderRejectStatusReducer.success && getOrderRejectStatusReducer.success.data.data;

    return (
        <div className="box-colored bg-light-gray pb-10">
        {/*<div className="form-block">*/}
            <form onSubmit={onSubmit}>
                <SelectField
                    name="order_status"
                    options={orderStatusOptions}
                    onChange={e => onHandleOrderStatusChange( e.target.value )}
                    label="Order Status"
                    value={values.order_status}
                    _label="label"
                    _value="value"

                    error={errors && errors.order_status}
                    touched={touched.order_status}
                />

                { values.order_status === 'accepted' && (
                    <>
                        <div className="row">
                            <div className="col-md-6">
                                <TextField placeholder="date"
                                           value={ getCurrentDate() }
                                           disabled
                                           name="Date"/>
                            </div>
                            <div className="col-md-6">
                                <SelectField name="time"
                                             options={fiveMinuteTimeFrame()}
                                             _label="label"
                                             _value="value"
                                             onChange={e => handleMinuteChange(e.target.value)}
                                             value={time}
                                />
                            </div>
                        </div>

                    <TextField
                        name="handled_by"
                        placeholder="Handled By"
                        onChange={handleChange}
                        value={values.handled_by}
                        error={errors && errors.handled_by}
                        touched={touched.handled_by}
                    />
                    </>)}

                { values.order_status === 'rejected' && (<SelectField
                    name="rejection_reason_id"
                    options={orderRejectStatus}
                    onChange={handleChange}
                    label="Rejected Reason"
                    value={values.rejection_reason_id}
                    _label="name"
                    _value="id"

                    error={errors && errors.order_status}
                    touched={touched.order_status}
                />)}
                <div className="button-wrapper text-center">
                    <button className="btn-theme btn-blue w-100" type="submit" >
                        { updateOrderReducer && updateOrderReducer.processing ? <GrowingLoader /> : `Update`}
                    </button>
                </div>
            </form>
        {/*</div>*/}
        </div>
    )
};

export default OrderStatusUpdateForm;