import React from "react";
import Home from "../home/Home";
import Dashboard from "../dashboard/Dashboard";
import Orders from "../order/Orders";
import Branches from "../branches/Branches";
import Reviews from "../review/Reviews";
import SpecificDashboard from "../branches/specific/SpecificBranchDashboard";
import OrderDetail from "../order/OrderDetail";
import Account from "../account/Account";
import SalesReport from "../report/sales/SalesReport";
import Reservations from "../reservations/Reservations";
import Reservation from "../reservations/details/Reservation";
import Timeslot from "../reservations/timeslot/Timeslot";
import {Redirect} from "react-router";
import FaultReport from "../report/fault/FaultReport";
import Integration from "../integration";
import FoodicsCallback from "../integration/foodics-callback";
import PartnerCategory from "../partner/category/PartnerCategory";
import PartnerIntegrations from "../partner/integration/list/PartnerIntegrations";

const route = ( path, title, exact, component ) => ({ path, title, exact, component })

export const RoutesState =
  {
    data: {
      list:
        [
          route( `/`, `Home`,'exact', <Home/> ),
          route( `/login`, `Home`,'exact', <Redirect to={Home}/> ),
          route( `/dashboard`, `Dashboard`,'exact', <Dashboard/> ),
          route( `/branches`, `Branch`,'exact', <Branches/> ),
          route( `/branches/:id([0-9]{0,9})`, `BranchInfo`, '', <SpecificDashboard/> ),
          route( `/reviews`, `Reviews`,'exact', <Reviews/> ),
          route( `/orders`, `Orders`,'exact', <Orders/> ),
          route( `/reservations`, `Reservations`,'exact', <Reservations/> ),
          // route( `/reservations/:id([0-9]{0,9})/view`, `Reservations`,'exact', <Reservation/> ),
          route( `/order/:id([0-9]{0,9})/view`, `Orders Details`,'exact', <OrderDetail/> ),
          route( `/account`, `Account`,'exact', <Account/> ),
          route( `/report`, `Sales Report`,'exact', <SalesReport/> ),
          route( `/fault-report`, `Fault Report`,'exact', <FaultReport/> ),
          route( `/integration`, `Integration`,'exact', <Integration/> ),
          route( `/foodics-callback`, `Foodics Callback`,'exact', <FoodicsCallback/> ),
          route( `/partner-category-map`, `Partner Category Map`,'exact', <PartnerCategory/> ),
          route( `/partner-integration`, `Partner Integration`,'exact', <PartnerIntegrations/> ),
        ]
    }
  };

