import React, {memo} from "react";
import {Link} from "react-router-dom";
import Tags from "../../../../common/Tags/Tags";
import {fullDate12Hrs} from "../../../../../utils/DateTimeUtils";
import {useDispatch, useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";
import getPartnerIntegrationIndex  from "../../../../../ajax/actions/partner/integration/partner_integration_index_get";
import swal from "sweetalert";
import GrowingLoader from "../../../../common/loaders/GrowingLoader";
import deletePartnerIntegration from "../../../../../ajax/actions/partner/integration/partner_integration_delete";
import { togglePartnerIntegrationForm, onFormModeChangeUpdate } from "./PartnerIntegrationList-Action";
import { setPartnerIntegrationData } from "../form/PartnerIntegrationForm-Actions";

const PartnerIntegartionItem = ({partners}) => {
    const {id, created_at, partner, status, partner_restaurant_branches} = partners;
    const reduxState = useSelector(state => state);
    const { indexPartnerIntegrationReducer } = reduxState;
    
    let dispatch = useDispatch();

    const processingIndex = indexPartnerIntegrationReducer.processing;
    
    const onIndexConfirmation = ( payload ) => {
        swal( {
            icon: "warning",
            title:'Are you sure you want to index this branch?',
            text:'Beware all of your list will be removed and fresh data will be retrieved.',
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Index",
                    value: payload,
                }
            }
        }).then((value) => {
            if (value) {
                const finalPayload = {
                    branch_id: payload.branch_id,
                    restaurant_id: payload.partner_restaurant_id,
                    partner_id: payload.partner_id,
                    id:payload.id
                }
                dispatch(getPartnerIntegrationIndex(finalPayload))
            }
        })
    };

    const onDeleteConfirmation = (branch_id, id) => {
        console.log('branch', branch_id, id);
        swal('Are you sure you want to delete this branch?',{
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id,
                }
            }
        }).then((value) => {
            if (value) {
                dispatch(deletePartnerIntegration(branch_id, id))
            }
        })
    };

    const updateToggle = ( payload ) => {
        dispatch(togglePartnerIntegrationForm())
        dispatch(onFormModeChangeUpdate(true))
        dispatch( setPartnerIntegrationData( payload ) )
    }

    return (
        <>
            <tr >
                <td>{id}</td>
                <td>{fullDate12Hrs(created_at)}</td>
                <td>{partner.name}</td>
                <td>{partner.type}</td>            
                <td><Tags status={status ? 'active' : 'inactive'}/></td>
                <td className="buttons-block" data-toggle="collapse" data-target={`#accordion-${id}`} class="clickable">
                    <a href="javascript:void(0)"
                    > <i className="fa-eye" data-tip="View"/></a>
                </td>
                <ReactTooltip backgroundColor="#BF9F60"/>
            </tr>
            <tr>
                <td colspan="6">
                     <div id={`accordion-${id}`} class="collapse">
                        <table className="table table-striped  ">
                            <thead>
                                <tr>
                                    <th>Branch</th>
                                    <th>External Branch ID</th>
                                    <th>External Branch Name</th>
                                    <th>External Group Menu ID</th>
                                    <th>External Group Menu Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {partner_restaurant_branches.length ? partner_restaurant_branches.map((r, key )=> (<tr key={key}>
                                    <td><Link to={`/branches/${r.branch.id}/info` }>{ r.branch.name}</Link></td>
                                    <td>{ r.external_branch_id}</td>
                                    <td>{r.external_branch_name || '-' }</td>
                                    <td>{r.external_group_menu_id}</td>
                                    <td>{r.external_group_menu_name}</td>
                                    <td>
                                        <>
                                            {r.can_index ?
                                                <>
                                                    {processingIndex && indexPartnerIntegrationReducer.id === r.id ?
                                                        <GrowingLoader /> : <i className="fa-history" data-tip="Index" onClick={() => onIndexConfirmation(r)} /> }
                                                </>
                                                : null
                                            }
                                            {partner.source === 'external' ?
                                                <>
                                                    &nbsp; <i className="fa-pencil" data-tip="Update" onClick={() => updateToggle( r )} /> &nbsp;
                                                    <i className="fa-trash" data-tip="Delete" onClick={() => onDeleteConfirmation(r.branch_id, r.id)} /> &nbsp;
                                                </> :
                                            null}

                                        </>
                                    </td>
                                </tr>)) : <tr><td colspan={5}> No data available </td></tr>}
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
           
        </>
        
        

    )
};

export default memo(PartnerIntegartionItem);